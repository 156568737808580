.bridge__container {
    position: relative;
}

.bridge {
    max-width: 80%;
    margin-bottom: 70px;
}

.bridge__label {
    font-size: 18px;
    margin-bottom: 10px;
    color: #4789ca;
    text-shadow: 0 0 8px #8383e284;
}

.bridge__foundation, .bridge__moving__part {
    filter: drop-shadow(0 0 8px #3a3a90);
}

.bridge__foundation.raise, .bridge__moving__part.raise {
    filter: drop-shadow(0 0 4px #ff8059);
}

.bridge__moving__part.raise {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: raise 10s linear forwards;
    fill: #FF3D00;
}

.bridge__moving__part.lower {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: lower 10s linear forwards;
}

.bridge__foundation.raise {
    fill: url('#a');
}

.ghost-reverse path, .ghost-reverse ellipse {
    transform: rotate(180deg);
}

@-moz-document url-prefix() {
    #gingerbreads path, #gingerbreads ellipse, #gingerbreads-reverse rect, #gingerbreads-reverse path, #gingerbreads-reverse ellipse {
        transform: rotate(150deg);
    }
}


.hide circle {
    fill: none;
}

@media screen and (min-width: 400px) {
    .bridge {
        margin-bottom: 100px;
    }
}

@media screen and (min-width: 768px) {
    .bridge {
        max-width: 65%;
    }
    .bridge__label {
        font-size: 22px;
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 992px) {
    .bridge {
        max-width: 50%;
    }
}

@media screen and (min-width: 1110px) {
    .bridge {
        max-width: 600px;
    }
}

@media screen and (orientation: landscape) and (max-device-width: 1000px) {
    .bridge {
        margin-bottom: 35px;
    }
    .bridge__label {
        margin-bottom: 10px;
    }
}

@media (prefers-color-scheme: dark) {
    .bridge__label {
        text-shadow: 0 0 8px #8383e2c7;
    }
    .bridge__foundation.raise, .bridge__moving__part.raise {
        filter: drop-shadow(0 0 6px #FF3D00);
    }
}

@media screen and (orientation: landscape) and (max-device-width: 1300px) {
    .bridge__label {
        font-size: 20px;
    }
}

@keyframes raise {
    0% { transform: translate(0, 0px); }
    20% { transform: translate(0, -40px); }
    40% { transform: translate(0, -70px); }
    70% { transform: translate(0, -70px); }
    100% { transform: translate(0, -70px); }
}

@keyframes lower {
    0% { transform: translate(0, -70px); }
    20% { transform: translate(0, -40px); }
    40% { transform: translate(0, 0); }
    70% { transform: translate(0, 0); }
    100% { transform: translate(0, 0); }
}