.online__message__container {
    position: absolute;
    top: 12px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}

.online__message__container--connecting {
    animation: animateDown 1s forwards;
}

.online__message__container--online {
    animation: animateUp 2.5s forwards;
}

.online__message__text {
    color: #ffffff;
    font-size: 12px;
    background-color: var(--secondary-color-opacity);
    padding: 5px 10px;
    border-radius: 13px;
}

@media screen and (min-width: 768px) {
    .online__message__container {
        top: 17px;
        right: 90px;
        left: auto;
        animation: animateLeft 1s forwards;
    }

    .online__message__container--online {
        animation: animateRight 2.5s forwards;
    }

    .online__message__text {
        font-size: 14px;
    }

    .online__message__container i {
        display: none;
    }
}

.lightbeam__container {
    position: absolute;
    top: 12px;
    right: 53px;
    transform: skew(-15deg, 5deg) scale(0.83);
    opacity: 0.3;
}

@media screen and (min-width: 768px) {
    .lightbeam__container {
        right: 65px;
        transform: skew(-15deg, 5deg) scale(1);
    }
}

.lightbeam__container--online {
    opacity: 0.9;
}

.lightbeam__container--connecting {
    animation: flickering 10s infinite;
}

.lightbeam {
    width: 50px;
    height: 200px;
    border-radius: 0%;
    background: conic-gradient(from -45deg at bottom,#00002b, var(--secondary-color) 1deg 90deg, #000 91deg);
    -webkit-mask: linear-gradient(var(--secondary-color-opacity), #3d8dcc00);
    position: absolute;
    top: 13px;
}

.websocketOnline {
    background: var(--secondary-color);
    width: 50px;
    height: 30px;
    border-radius: 30px / 19px;
    font-size: 25px;
    line-height: 31px;
    color: #ffffff;
    position: absolute;
}

@keyframes flickering {
    0% { opacity: 0.3 }
    3% { opacity: 0.5 }
    7% { opacity: 0.3 }
    10% { opacity: 0.5 }
    13% { opacity: 0.3 }
    15% { opacity: 0.5 }
    20% { opacity: 0.3 }
    25% { opacity: 0.6 }
    30% { opacity: 0.4 }
    35% { opacity: 0.6 }
    40% { opacity: 0.5 }
    45% { opacity: 0.6 }
    50% { opacity: 0.3 }
    55% { opacity: 0.6 }
    60% { opacity: 0.4 }
    65% { opacity: 0.6 }
    70% { opacity: 0.3 }
    75% { opacity: 0.6 }
    80% { opacity: 0.2 }
    85% { opacity: 0.5 }
    90% { opacity: 0.3 }
    95% { opacity: 0.6 }
}

@keyframes animateDown {
    0% { top: -30px; opacity: 0 }
    100% { top: 12px; opacity: 1 }
}

@keyframes animateUp {
    0% { top: 12px; opacity: 1 }
    30% { top: 12px; opacity: 1 }
    100% { top: -30px; opacity: 0 }
}

@keyframes animateLeft {
    0% { right: 10px; opacity: 0 }
    100% { right: 90px; opacity: 1 }
}

@keyframes animateRight {
    0% { right: 90px; opacity: 1 }
    30% { right: 90px; opacity: 1 }
    100% { right: 10px; opacity: 0 }
}