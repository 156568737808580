.ship {
    position: absolute;
    width: 25px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 35px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;
    z-index: -1;
    filter: drop-shadow(0 0px 1px #1d8bdf);
}

.sail {
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation: sail 12s linear infinite;
}

@media screen and (min-width: 768px) { 
    .ship {
        width: 45px;
        top: 70px;
    }
}

@media screen and (orientation: landscape) and (max-width: 767px) {
    .ship {
        width: 45px;
        top: 70px;
    }
}

@media screen and (min-width: 1110px) {
    .ship {
        width: 55px;
        top: 80px;
    }
}

@media (prefers-color-scheme: dark) {
    .ship {
        filter: drop-shadow(0 0px 2px #1d8bdf);
    }
}

@keyframes sail {
    0% { transform: scale(1) translate(0, 0px); opacity: 0; }
    30% { transform: scale(1) translate(0, 0px); opacity: 0; }
    40% { transform: scale(1) translate(0, 0px); opacity: 1; }
    50% { transform: scale(1.1) translate(0, 10px); }
    60% { transform: scale(1.2) translate(0, 20px); }
    65% { transform: scale(1.225) translate(0, 25px); opacity: 1; }
    70% { transform: scale(1.25) translate(0, 30px);  }
    80% { transform: scale(1.3) translate(0, 40px); }
    90% { transform: scale(1.35) translate(0, 44px); opacity: 0; }
    100% {transform: scale(1.4) translate(0, 48px); }
}