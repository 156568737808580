body {
  background-color: #00002b;
}

p {
  margin: 0 auto;
}

.App {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  margin-top: 25vh;
}

@media screen and (orientation: landscape) and (max-device-width: 1000px) {
  .App {
    margin-top: 5vh;
  }
}

@media screen and (orientation: landscape) and (max-device-width: 800px) {
  .App {
    margin-top: 10vh;
  }
}